import { Link } from "gatsby";
import { split_sequence } from "./utils";
import categories from "../categories";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PaginateWidget from "./paginatewidget";
function PeopleSection({ people, cta, className, pagination }) {
  return (
    <div className={className + " max-w-full"}>
      <div className="flex flex-row items-center justify-between">
        <h3 className="md:text-2xl lg:text-4xl font-semibold text-blue-800">
          {cta}
        </h3>
        {pagination ? (
          <PaginateWidget
            pagination={pagination}
            className="text-blue-800 text-sm md:text-2xl font-extralight"
          />
        ) : null}
      </div>

      <div className="overflow-x-scroll md:pb-10 hide-scroll-bar">
        <ul className="flex flex-row flex-nowrap md:flex-wrap gap-y-10 gap-x-5 pt-10 flex-baseline">
          {people.map((peeps, index) => (
            <li
              key={index}
              className="w-52 grow lg:flex-none"
              key={peeps["person_code"]}
            >
              <div className="flex flex-col w-52 grow">
                <Link to={"/pessoas/" + peeps["person_code"]} className="">
                  {/* <GatsbyImage
                    image={getImage(peeps["person_image"])}
                    alt={peeps["person_name"]}
                  /> */}
                  <GatsbyImage
                    image={getImage(peeps["person_image"])}
                    // src={book["image"]}
                    alt={peeps["person_name"]}
                  />
                  {/* <img
                    className="transition hover:transform hover:duration-150 hover:-translate-y-4 shadow-lg min-w-full w-52 grow"
                    src={peeps["person_image"]}
                    alt={peeps["person_name"]}
                    loading="lazy"
                  /> */}
                  <p className="text-xl font-light text-blue-800 my-2">
                    {peeps["person_name"]}
                  </p>
                </Link>

                <div className="flex flex-row flex-wrap gap-2 ">
                  {split_sequence(peeps["person_attributes"]).map((att) => (
                    <Link
                      to={"/setor/" + categories["url_person"][att]}
                      key={att}
                    >
                      <div className="px-3 py-2 bg-blue-100 rounded-sm text-xs text-blue-800 font-light hover:bg-blue-200 shadow-lg">
                        {att}
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="invisible md:visible flex flex-row items-center justify-end">
        {pagination ? (
          <PaginateWidget
            pagination={pagination}
            className="text-blue-800 text-sm md:text-2xl font-extralight"
          />
        ) : null}
      </div>
    </div>
  );
}

export default PeopleSection;

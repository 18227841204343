// import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export function normalize_graphql_data(data) {
  // if root key node is present push data to normalized data if not return original
  let sample = data[0];

  if (sample.node) {
    let normalized_data = [];
    data.forEach((node) => {
      normalized_data.push(node.node);
    });
    return normalized_data;
  } else {
    return data;
  }
}

export function get_random_top(
  data,
  limit,
  att,
  ascending,
  sample_space = 100
) {
  let items_arr = Object.keys(data);

  if (ascending) {
    items_arr.sort((a, b) => data[b][att] - data[a][att]);
  } else {
    items_arr.sort((a, b) => data[a][att] - data[b][att]);
  }

  let top_items = items_arr.slice(0, sample_space);
  // shuffle top_items
  top_items = top_items.sort(() => Math.random() - 0.5);

  top_items = top_items.slice(0, limit).map((item) => data[item]);

  return top_items;
}

export function get_containing(people, number_of_people, att) {
  let people_arr = Object.keys(people);
  // sort people_arr by "people_rank"
  let ranking_key = "person_ranking";
  if (att === "book_code") {
    ranking_key = "recommended_volume";
    people_arr.sort((a, b) => people[b][ranking_key] - people[a][ranking_key]);
  } else {
    people_arr.sort((a, b) => people[a][ranking_key] - people[b][ranking_key]);
  }
  let random_people = [];
  for (let i = 0; i < Math.min(number_of_people, people_arr.length); i++) {
    let random_person = people_arr[i];
    let person_data = people[random_person];
    person_data[att] = random_person;
    random_people.push(person_data);
  }
  return random_people;
}

export function get_containing_filt(
  people,
  number_of_people,
  att,
  category_id,
  category
) {
  let people_arr = Object.keys(people);

  let ranking_key = "person_ranking";
  if (att === "book_code") {
    ranking_key = "recommended_volume";
    people_arr.sort((a, b) => people[b][ranking_key] - people[a][ranking_key]);
  } else {
    people_arr.sort((a, b) => people[a][ranking_key] - people[b][ranking_key]);
  }

  let random_people = [];
  let count = 0;
  for (let i = 0; i < people_arr.length; i++) {
    let random_person = people_arr[i];
    let person_data = people[random_person];
    person_data[att] = random_person;
    if (person_data[category_id].includes(category)) {
      person_data[att] = random_person;
      random_people.push(person_data);
      count++;
    }
    if (count >= number_of_people) {
      break;
    }
  }

  return random_people;
}

// While number of people is less than 10 keep looking for more people
export function get_random_containing(
  people,
  number_of_people,
  att,
  category_id,
  category
) {
  let people_arr = Object.keys(people);
  let random_people = [];
  let counter = 0;
  while (random_people.length < number_of_people && counter < 500) {
    let rand = Math.floor(Math.random() * people_arr.length);
    let random_person = people_arr[rand];
    let person_data = people[random_person];
    if (person_data[category_id].includes(category)) {
      person_data[att] = random_person;
      random_people.push(person_data);
    }
    counter++;
  }
  return random_people;
}

export function get_random(people, number_of_people, att) {
  let people_arr = Object.keys(people);

  let random_people = [];
  for (let i = 0; i < number_of_people; i++) {
    let rand = Math.floor(Math.random() * people_arr.length);
    let random_person = people_arr[rand];
    let person_data = people[random_person];
    person_data[att] = random_person;
    random_people.push(person_data);
  }
  return random_people;
}

export function split_sequence(attributes) {
  let split_attributes = attributes.split(",");
  return split_attributes;
}

// export function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }

import { Link } from "gatsby";
import AffiliateButton from "./affiliatebutton";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PaginateWidget from "./paginatewidget";

function BookSection({ books, cta, className, pagination }) {
  return (
    <div className={className + " max-w-full"}>
      <div className="flex flex-row items-center justify-between">
        <h3 className=" md:text-2xl lg:text-4xl font-semibold text-blue-800">
          {cta}
        </h3>
        {pagination ? (
          <PaginateWidget
            pagination={pagination}
            className="text-blue-800 text-sm md:text-2xl font-extralight"
          />
        ) : null}
      </div>

      <div className="overflow-x-scroll pb-10 hide-scroll-bar">
        <ul className="flex flex-row flex-nowrap md:flex-wrap gap-y-10 gap-x-5 pt-10">
          {books.map((book, index) => (
            <li
              key={book["book_code"]}
              className="flex-none w-32 lg:w-40 flex flex-col min-h-fit"
            >
              <Link
                to={"/livros/" + book["book_code"]}
                className="flex grow flex-col"
              >
                <div className="">
                  <GatsbyImage
                    image={getImage(book["image"])}
                    alt={book["name"]}
                    className="shadow-xl"
                  />
                </div>

                <p className="text-xl font-light text-blue-800 my-2 line-clamp-3">
                  {book["name"]}
                </p>
                <p className="text-lg font-extralight text-blue-800 my-2">
                  {book["author"]}
                </p>
              </Link>
              <AffiliateButton
                affiliate_link={book["amazon_affiliate"]}
                size="small"
                className="flex-end"
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="invisible md:visible flex flex-row items-center justify-end">
        {pagination ? (
          <PaginateWidget
            pagination={pagination}
            className="text-blue-800 text-sm md:text-2xl font-extralight"
          />
        ) : null}
      </div>
    </div>
  );
}

export default BookSection;

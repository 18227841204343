export default function AffiliateButton({ affiliate_link, size }) {
  //if size is not set

  let default_class =
    "px-2 py-2 bg-gray-800 text-white rounded-sm shadow-lg text-center ";

  if (size == "small") {
    default_class =
      "px-2 py-2 bg-gray-800 text-white text-sm rounded-sm shadow-lg text-center ";
  }

  return (
    <a
      className={default_class}
      href={affiliate_link}
      target="_blank"
      rel="nofollow sponsored"
    >
      <button>Ver na Amazon</button>
    </a>
  );
}

import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { ArrowRight, ArrowLeft } from "react-feather";

function PaginateWidget({ pagination, className }) {
  //if size is not set

  let loc = useLocation().pathname;

  let currentPage = pagination.currentPage;
  let numPages = pagination.numPages;
  let base = pagination.base
    ? pagination.base
    : loc.substring(0, loc.lastIndexOf("/"));

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1
      ? `${base}/`
      : `${base}/${(currentPage - 1).toString()}`;

  const nextPage = `${base}/${(currentPage + 1).toString()}`;

  const arrowSize = 30;

  return (
    <div className={"flex flex-row flex-nowrap items-center " + className}>
      {!isFirst && (
        <Link to={prevPage} rel="prev">
          <ArrowLeft size={arrowSize} strokeWidth={1} />
        </Link>
      )}

      <span>
        {currentPage} / {numPages}
      </span>

      {/* {Array.from({ length: numPages }, (_, i) => (
        <Link
          key={`pagination-number${i + 1}`}
          to={`${base}/${i === 0 ? "" : i + 1}`}
        >
          {i + 1}
        </Link>
      ))} */}
      {!isLast && (
        <Link to={nextPage} rel="next">
          <ArrowRight size={arrowSize} strokeWidth={1} />
        </Link>
      )}
    </div>
  );
}

export default PaginateWidget;
